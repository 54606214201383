import React from 'react'
import classNames from 'classnames'
import CodeverseLogo from '../../../../images/logos/logo-codeverse-nova-text.png'
import Image from 'next/image'
import { AnimatePresence, motion } from 'framer-motion'

interface EmptySlideProps {
  isFetchingPublicApps: number
}

const EmptySlide: React.FC<EmptySlideProps> = ({ isFetchingPublicApps }) => {
  const emptyClassnames = classNames('relative rounded-2xl bg-[#090E2B]')
  return (
    <div className="pr-4">
      <div
        className={emptyClassnames}
        style={{
          height: 0,
          paddingTop: '150.85%',
          backgroundSize: 'cover',
        }}
      >
        <AnimatePresence>
          {isFetchingPublicApps && (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                duration: 0.8,
              }}
            >
              <Image
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform bg-opacity-20 !opacity-50"
                width={40}
                height={40}
                alt="NovaWhite"
                src={'/images/home/nova-white.svg'}
              />
            </motion.div>
          )}
          {!isFetchingPublicApps && (
            <Image
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform bg-opacity-20 !opacity-[.05]"
              width={40}
              height={40}
              alt="NovaWhite"
              src={'/images/home/nova-white.svg'}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default EmptySlide
