'use client'
import React, { useEffect, useState, useMemo, useRef } from 'react'

import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { useRouter, useSearchParams } from 'next/navigation'
// import { useUIState } from '@codeverse-studio/containers/Context/UIStateContainer'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      id="GameSlider_Next"
      className={`${className} !right-0`}
      style={style}
      onClick={onClick}
    >
      <FontAwesomeIcon color="#ffffff" size="lg" icon={faArrowRight} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      id="GameSlider_Prev"
      className={`${className} !left-0`}
      style={style}
      onClick={onClick}
    >
      <FontAwesomeIcon color="#ffffff" size="lg" icon={faArrowLeft} />
    </div>
  )
}

const sliderLocalStyles = `

`

const GameSlider = (props) => {
  const sliderEl = useRef<any>(null)
  const router = useRouter()
  const searchParams = useSearchParams()
  const initialSlide = searchParams.get('friends_games')
  const [currentSlide, setCurrentSlide] = useState(initialSlide || 0)
  // const { state: UIState, closeUI } = useUIState()

  // const { mobileNavShown, varsityTutorsFirstLogin } = UIState

  useEffect(() => {
    const vtLogin = localStorage.getItem('vtLogin') === 'true'
    if (
      sliderEl.current &&
      // !mobileNavShown &&
      // !varsityTutorsFirstLogin &&
      vtLogin
    ) {
      sliderEl.current.slickGoTo(0)
    }
  }, [])

  const sliderSettings = {
    dots: false,
    arrows: true,
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 3,
    swipeToSlide: false,
    autoplay: false,
    touchMove: false,
    initialSlide: initialSlide || 0,
    currentSlide: currentSlide,
    nextArrow: (
      <SampleNextArrow className="w-h-12 flex h-12 items-center justify-center" />
    ),
    prevArrow: (
      <SamplePrevArrow className="w-h-12 flex h-12 items-center justify-center" />
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <>
      <style>{sliderLocalStyles}</style>
      <div id="GameSlider" className="mb-9 w-full min-w-0 md:mb-4">
        <Slider
          ref={sliderEl}
          className="friends-game-slider w-full min-w-0 overflow-hidden"
          afterChange={(newIndex) => {
            const appCategory = searchParams.get('app_category')
            if (appCategory) {
              router.push(
                props.path +
                  '/?friends_games=' +
                  newIndex +
                  '&app_category=' +
                  appCategory
              )
            } else {
              router.push(props.path + '/?friends_games=' + newIndex)
            }
          }}
          beforeChange={(newIndex) => {
            setCurrentSlide(newIndex)
          }}
          {...sliderSettings}
        >
          {props.children}
        </Slider>
      </div>
    </>
  )
}

export default GameSlider
