'use client'
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { StreamApp, FlatFeed } from 'react-activity-feed-18'

import axios from 'axios'
import classNames from 'classnames'
import ActivityContainer from './ActivityContainer'
import Cookies from 'js-cookie'
import { SatelliteClient } from '@codeverse/satellite-client'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useUIStore } from 'apps/studio-shared/src/Stores'

const refreshIcon =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/icon-load-more.svg'
const emptyIcon =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/icon-feed-empty.svg'
const nova =
  'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/codeverse-nova-gradient%402x.png'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  // currentUser: any
  // sessionToken: string
  streamToken: string
  followings?: any
  projects?: any
  // onFollowClick: () => void
  // onUnFollowClick: () => void
  // onUserNameClick: () => void
  // handleGameClick: () => void
  // onNotifierIconClick: () => void
  isMobile: boolean
}

const FeedList = function FeedList(props: Props, ref) {
  const {
    // currentUser,
    // sessionToken,
    streamToken,
    // setStreamToken,
    followings = [],
    projects = [],
    // onFollowClick,
    // onUnFollowClick,
    // onNotifierIconClick,
    // onUserNameClick,
    // handleGameClick,
    isMobile = false,
    ...other
  } = props

  const router = useRouter()
  const { setProfileModal } = useUIStore()

  // const {
  //   theme: { notifications },
  // } = useContext(ThemeContext)

  const [followingList, setFollowingList] = useState(followings)
  const [ownProjectsList, setOwnProjectsList] = useState(projects)
  const [projectsLoaded, setProjectsLoaded] = useState(false)
  const [followingListFetched, setFollowingListFetched] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const isFollowingsLoaded = useRef(false)
  const isProjectsLoaded = useRef(false)

  const auth = JSON.parse(localStorage.getItem('auth') || '{}')
  const { accessToken } = auth
  const { currentUserId } = auth

  const getUserPublicApps = (userId: string) => {
    SatelliteClient.platform
      .get(`/users/${userId}/public_apps`, {
        headers: Object.assign(
          {},
          { Accept: 'application/vnd.api+json' },
          { 'content-type': 'application/vnd.api+json' }
        ),
        query: {
          current_user_id: userId,
          access_token: accessToken,
        },
      })
      .then((response) => {
        if (isProjectsLoaded.current) {
          setOwnProjectsList(response.data.data)
          setProjectsLoaded(true)
        }
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  useEffect(() => {
    isProjectsLoaded.current = true
    if (!projectsLoaded && currentUserId) {
      if (ownProjectsList.length < 1 || !ownProjectsList) {
        // getUserPublicApps(currentUserId)
      }
    }
    return () => {
      isProjectsLoaded.current = false
    }
  }, [currentUserId, ownProjectsList, projectsLoaded])

  // const getFollowings = (userId: string) => {
  //   setIsFetching(true)
  //   return axios
  //     .get(`${process.env.NX_API_URL}/users/${userId}/followings`, {
  //       headers: Object.assign(
  //         {},
  //         { Accept: 'application/vnd.api+json' },
  //         { 'content-type': 'application/vnd.api+json' }
  //       ),
  //       params: {
  //         current_user_id: userId,
  //         access_token: sessionToken,
  //       },
  //     })
  //     .then((response) => {
  //       setFollowingListFetched(true)
  //       setFollowingList(response.data.data)
  //     })
  //     .catch((error) => {
  //       setFollowingListFetched(true)
  //       //console.log(error)
  //     })
  // }

  // useEffect(() => {
  //   isFollowingsLoaded.current = true
  //   if (
  //     currentUser &&
  //     followingList.length < 1 &&
  //     !isFetching &&
  //     !followingListFetched
  //   ) {
  //     getFollowings(currentUser.id)
  //   }
  // }, [
  //   currentUser,
  //   followingList,
  //   setFollowingList,
  //   followingList,
  //   isFetching,
  //   followingListFetched,
  // ])

  const feedItemStyles = `
    .feed-list__item:nth-child(2) {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }

    .raf-load-more-button, .raf-new-activities-notification {
      display: block;
      text-align: center;
      width: 50%;
      min-width: 150px;
      font-size: 14px;
      background-color: #5225ee;
      border-radius: 20px;
      color: #eef6fd;
      margin: 12px auto;
      padding: 8px 0;
      position: relative;
    }

    .raf-load-more-button button, .raf-new-activities-notification a {
      display: block;
      line-height: 24px;
    }

    .raf-load-more-button button {
      text-align: center;
      width: 100%;
    }

    .raf-new-activities-notification a::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(${refreshIcon});
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-right: 8px;
      vertical-align: middle;
    }
  `

  const notifications = {
    banner: 'bg-darkalt text-white text-center py-4',
    buttons: {
      outline:
        '!px-6 !py-4 rounded-4xl text-sm h-10 bg-gradient-to-r bg-transparent from-nebula to-sun !shadow-none align-middle',
    },
  }

  const Stream = useMemo(() => {
    return (
      <StreamApp
        //@ts-ignore
        apiKey={'yz8wusyfmsez'}
        appId="1152053"
        token={streamToken}
      >
        <FlatFeed
          notify
          feedGroup="user_timeline"
          options={{ limit: 20 }}
          Placeholder={() => {
            return (
              <div
                className="flex h-auto w-full flex-col items-center text-center"
                style={{ padding: '10% 0' }}
              >
                <img src={emptyIcon} alt="" className="mx-auto" />
                <div className="opacity-50">
                  <h4 className="text-white">No updates yet</h4>
                  <p className="px-24 text-white">
                    Come back to see when
                    <br />
                    your friends update games,
                    <br />
                    when new contests launch,
                    <br />
                    and more!
                  </p>
                </div>
              </div>
            )
          }}
          LoadingIndicator={() => {
            return (
              <div className="h-96">
                <Image
                  className=""
                  src={
                    'https://s3.amazonaws.com/uploads.codeverse.com/production/helios/codeverse-nova-white.png'
                  }
                  height={48}
                  width={48}
                  alt="Loading..."
                />
              </div>
            )
          }}
          Activity={(props) => (
            <ActivityContainer
              feedType="timeline"
              currentUser={currentUserId}
              sessionToken={accessToken}
              item={props.activity}
              followingList={followingList}
              ownProjectsList={ownProjectsList}
              setOwnProjectsList={setOwnProjectsList}
              setFollowingList={setFollowingList}
              onFollowClick={() => {}}
              onUnFollowClick={() => {}}
              onUserNameClick={(e, username: string) => {
                router.push(`/?username=${username}`)
              }}
              handleGameClick={(id) => {
                router.push(`/play/${id}`)
              }}
              isMobile={isMobile}
              theme={notifications}
              isOpen={false}
              {...props}
            />
          )}
        />
      </StreamApp>
    )
  }, [streamToken])

  return (
    <div className="feed-list__container font-poppins-regular mb-6 w-full rounded-3xl bg-[#090E2B] text-white">
      <style>{feedItemStyles}</style>
      {Stream}
    </div>
  )
}

export default FeedList
