'use client'

import { LaunchedApp } from 'apps/studio/hooks/data/LaunchedApps/LaunchedApps'
import GameSlider from './GameSlider'
import GameCard from './GameCard'
import EmptySlide from './EmptySlide'
import times from 'lodash/times'
import { useEffect, useMemo, useState } from 'react'
import { useIsFetching, useQueries, useQuery } from '@tanstack/react-query'
import {
  getUserFollowings,
  getUserPublicApps,
} from '../../Data/Follow/FollowFetch'
import useAuth from '../../Hooks/Auth/useAuth'

interface NameProps {
  isFetched: boolean
  path: string
}
export default function FriendsGames({ isFetched, path }: NameProps) {
  const userPublicApps = [null]
  const [followingsList, setFollwingsList] = useState([])
  const [dateLimit, setDateLimit] = useState(new Date())
  const isFetchingPublicApps = useIsFetching({
    queryKey: ['publicApps'],
  })
  const { currentUserId } = useAuth()
  const { data: followings } = useQuery({
    queryKey: ['followings', currentUserId],
    queryFn: () => getUserFollowings(currentUserId),
    refetchOnWindowFocus: false,
    enabled: !!currentUserId,
  })

  const userQueries = useQueries({
    queries: followingsList.map((id) => {
      return {
        queryKey: ['publicApps', id],
        queryFn: () => getUserPublicApps(id),
        // refetchOnWindowFocus: false,
      }
    }),
  })

  useEffect(() => {
    if (!followings) return
    const date = new Date()
    let twoWeeks = date.getTime()
    twoWeeks -= 30 * 24 * 60 * 60 * 1000
    //@ts-ignore
    setDateLimit(date.setTime(twoWeeks))
    const followingsIDs = followings.map(
      (following: any) => following.following.id
    )

    setFollwingsList(followingsIDs)
  }, [followings])

  const userGames = useMemo(() => {
    if (userQueries) {
      //@ts-ignore
      const userGames = []
      userQueries.map((userQuery: any) => {
        if (userQuery.data) {
          //@ts-ignore
          userQuery.data.map((publicApp: any) => userGames.push(publicApp))
        }
      })

      //@ts-ignore
      return userGames
    } else {
      return []
    }
  }, [isFetchingPublicApps, userQueries])

  const EmptySlides = useMemo(() => {
    if (!isFetched) {
      return times(4).map((num, index) => {
        return (
          <EmptySlide key={index} isFetchingPublicApps={isFetchingPublicApps} />
        )
      })
    } else if (isFetched && userPublicApps?.length > 2) {
      return times(1).map((num, index) => {
        return (
          <EmptySlide key={index} isFetchingPublicApps={isFetchingPublicApps} />
        )
      })
    } else if (isFetched && userPublicApps?.length > 1) {
      return times(2).map((num, index) => {
        return (
          <EmptySlide key={index} isFetchingPublicApps={isFetchingPublicApps} />
        )
      })
    } else if (isFetched && userPublicApps?.length > 0) {
      return times(3).map((num, index) => {
        return (
          <EmptySlide key={index} isFetchingPublicApps={isFetchingPublicApps} />
        )
      })
    } else {
      if (isFetched) {
        return times(4).map((num, index) => {
          return (
            <EmptySlide
              key={index}
              isFetchingPublicApps={isFetchingPublicApps}
            />
          )
        })
      }
    }
  }, [isFetched, userPublicApps, isFetchingPublicApps])

  const sortedUserGames = useMemo(() => {
    const tempSortedFriendsGames = userGames.sort((a, b) =>
      //@ts-ignore
      b.created_at > a.created_at ? 1 : -1
    )
    //@ts-ignore
    const filteredFriendsGames = tempSortedFriendsGames.filter((game) => {
      //@ts-ignore
      const createdAt = new Date(game.created_at)
      return game && createdAt > dateLimit
    })
    return filteredFriendsGames
  }, [dateLimit, userGames])

  const sortedUserGamesRender = useMemo(() => {
    return sortedUserGames?.map((app: any) => {
      return <GameCard key={app.id} type="friends" launchedApp={app} />
    })
  }, [sortedUserGames])

  return (
    <div id="FriendsGames" className="overflow-hidden pb-8">
      <GameSlider path={path}>
        {sortedUserGamesRender}
        {/* {!isFetched && <div>Loading...</div>} */}
        {EmptySlides}
      </GameSlider>
      {/* {!isFetchingPublicApps && sortedUserGames.length < 1 && NoFriendsCard} */}
    </div>
  )
}
