import capitalize from 'lodash/capitalize'
import React, { useMemo } from 'react'
import moment from 'moment-timezone'

import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
// import { useResponsive } from '@codeverse/hooks'
import { LaunchedApp } from 'apps/studio/hooks/data/LaunchedApps/LaunchedApps'
import { useRouter, useSearchParams } from 'next/navigation'
import { motion } from 'framer-motion'
import useUIStore from '../../Stores/useUIStore'

interface GameCardProps {
  showPlayCount?: boolean
  showBottomAvatar?: boolean
  type?: string
  launchedApp: any
  key?: string
}

const GameCard: React.FC<GameCardProps> = ({
  showPlayCount,
  showBottomAvatar,
  type,
  launchedApp,
  key,
}) => {
  const { setProfileModal } = useUIStore()
  const router = useRouter()
  const searchParams = useSearchParams()
  const createdAt = useMemo(() => {
    return moment(launchedApp.created_at).fromNow()
  }, [launchedApp])
  const isMobile = false

  const imageClassnames = classNames('cursor-pointer p-0.5 rounded-full', {
    'mr-1': type === 'friends' && isMobile,
    'mr-2': type === 'friends' && !isMobile,
    border: type !== 'friends',
    'h-6 w-6': isMobile,
    'h-10 w-10': !isMobile,
  })

  const gameCardContainerClassnames = classNames(
    'cursor-pointer inline relative rounded-2xl bg-moon-darkest bg-opacity-40 max-w-[260px] pr-4',
    {
      // 'pr-4': type === 'friends',
    }
  )

  const avatarStyles = {
    backgroundImage:
      type === 'friends'
        ? 'linear-gradient(#5225EE, #DC02D9, #FF8500)'
        : 'none',
  }

  const emptyClassnames = classNames(
    'relative rounded-2xl bg-moon-darkest bg-opacity-40 cursor-pointer overflow-hidden'
  )

  return (
    <div className="pr-4" key={key}>
      <div
        className={emptyClassnames}
        style={{
          height: 0,
          paddingTop: '150.85%',
          backgroundSize: 'cover',
          backgroundImage: `url(${launchedApp.screenshot})`,
        }}
        onClick={() => {
          router.push(`/play/${launchedApp.id}`)
        }}
      >
        <div
          className="rounded-b-16 absolute bottom-0 left-0 right-0 flex h-3/4 flex-col items-start justify-end overflow-hidden"
          style={{
            backgroundImage: showBottomAvatar
              ? ''
              : 'linear-gradient(transparent 0%, transparent 50%, #0E1540 100%)',
          }}
        >
          {!showBottomAvatar && (
            <div className="ml-4 flex h-1/3 w-full cursor-pointer flex-row items-center justify-start self-center">
              <motion.div
                whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
                id="GameCard_UserAvatar"
                className={imageClassnames}
                style={avatarStyles}
                onClick={(e) => {
                  e.stopPropagation()
                  setProfileModal(true)
                  router.push(`/?username=${launchedApp?.username}`)
                }}
              >
                <img
                  className="block rounded-full"
                  src={launchedApp?.user_avatar}
                  alt={'avatar'}
                />
              </motion.div>
              <div className="font-jakarta-sans text-12 lg:text-16 flex flex-col font-medium text-white">
                <strong
                  id="GameCard_Username"
                  className="font-poppins-semibold leading-14 lg:leading-18 cursor-pointer text-xs text-white sm:text-sm"
                  onClick={(e) => {
                    e.stopPropagation()
                    // navigate(`/home/user/${publicApp.username}`)
                  }}
                >
                  {capitalize(launchedApp.username)}
                </strong>
                <small className="font-jakarta-sans leading-12 text-moon-light lg:leading-18 text-xs sm:text-sm">
                  {createdAt}
                </small>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GameCard
