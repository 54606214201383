'use client'
import { getStreamToken } from 'apps/studio/hooks/data/Users/Users'
import fetchApi from 'apps/studio/utils/fetch/fetchApi'
import { cookies } from 'next/headers'

import FeedList from 'apps/studio-shared/src/Components/Home/Social/FeedList'
import FriendsGames from 'apps/studio-shared/src/Components/Friends/FriendsGames'
import { useStreamToken } from 'apps/studio-shared/src/Data/User/useStreamToken'
import useSession from 'apps/studio-shared/src/Components/Studio/Hooks/useSession'

export default function Page(props) {
  const { data } = useStreamToken()
  // const isFetched = false

  // const cookieStore = cookies()
  // const auth = cookieStore.get('auth')
  // const { accessToken, currentUserId } = JSON.parse(auth?.value || '{}')

  // const userData = await fetchApi(getStreamToken(currentUserId))
  // const avatarId = userData.data.relationships.avatar.data.id
  // const userName = userData.data.attributes.referral_code

  // const streamToken = userData?.data.meta.stream_token

  return (
    <div className="flex w-full flex-col items-center pb-6">
      <h4 className="font-jakarta-sans w-full !pb-6 text-left font-semibold text-white">
        From Friends
      </h4>
      <div className="w-full max-w-full">
        <FriendsGames path="/" isFetched={false} />
      </div>

      <h4 className="font-jakarta-sans w-full !pb-6 text-left font-semibold text-white">
        Recent updates
      </h4>
      {data?.data.meta.stream_token && (
        <FeedList isMobile={false} streamToken={data?.data.meta.stream_token} />
      )}
    </div>
  )
}
